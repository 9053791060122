import { rewardsApi } from "@common/api/rewardsApi";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { IdType } from "@common/types/apiTypes";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCardVariants } from "@ops-design-system/components/BoCard/BoCard.constants";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { BoTextInput } from "@ops-design-system/components/BoTextInput/BoTextInput";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { grey, red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { OfferSnapshot } from "@ops-utils/models/OfferSnapshot.model";
import { ProspectOfferSnapshotType } from "@ops-utils/types/prospectTypes";
import { OfferCardRadioGroup } from "@ops/components/OfferCardRadioGroup/OfferCardRadioGroup";
import { StyledTab } from "@ops/components/ProspectEditor/shared/ProspectEditor.styled";
import { TabType } from "@ops/components/ProspectEditorTabs/configuration";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import { useProspectInState } from "@ops/hooks/useProspectInState";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import styled from "styled-components";

const SelectorContainer = styled.div`
  width: 40%;
`;

const StyledTitleText = styled(Body2)`
  color: ${grey["500"]};
  font-weight: ${FontWeight.Semibold};
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};
  margin-bottom: ${rhOpsSpacingPx(2)};
`;

const StyledErrorContainer = styled(BoCard).attrs({
  $variant: BoCardVariants.Warning,
})`
  color: ${red["500"]};
`;

export const PlansTab = () => {
  const {
    prospect: {
      id: prospectId,
      acquisitionCampaign,
      acquisitionMedium,
      offersnapshotId: existingOffersnapshotId,
      referralCode: existingReferralCode,
      meterId,
      rcid,
    },
    updateProspectState,
  } = useEnrollmentFlowContext();
  const {
    prospect,
    requestMonitor: prospectRequestMonitor,
    fetchProspect,
  } = useProspectInState(prospectId);
  const flash = useRhFlash();
  const [selectedOffer, setSelectedOffer] = useState<OfferSnapshot | null>(
    null
  );
  const [referralCode, setReferralCode] = useState<string>(
    existingReferralCode ?? ""
  );

  useEffect(() => {
    fetchProspect();
  }, [fetchProspect]);

  const [
    { requestMonitor: rafReferralRequestMonitor },
    {
      setPending: setRafReferralCodePending,
      setSuccess: setRafReferralCodeSuccess,
      setFailure: setRafReferralCodeFailure,
    },
  ] = useAjaxState();

  const dunsNumber = prospect?.meter?.duns;

  if (prospectRequestMonitor.isPending) {
    return <BoCircularProgress />;
  }

  if (prospectRequestMonitor.didFail) {
    flash.error("An error occurred. Unable to get prospect data.");

    return null;
  }

  const validateRafReferralCode = (code: string) => {
    setRafReferralCodePending();

    return rewardsApi.raf
      .validateReferralCode(code)
      .then(() => {
        setRafReferralCodeSuccess();
        return true;
      })
      .catch(() => {
        setRafReferralCodeFailure();
        flash.error("Invalid Referral Code entered. Please try again.");
        return false;
      });
  };

  const handleOfferSelect = (offerSnapshot: OfferSnapshot) => {
    setSelectedOffer(offerSnapshot);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let offersnapshot: ProspectOfferSnapshotType | undefined;
    let offersnapshotId: IdType | undefined;

    if (selectedOffer) {
      offersnapshot = {
        price2000Kwh: selectedOffer.price2000Kwh,
        priceTierSnapshots: selectedOffer.priceTierSnapshots ?? [],
        promoCode: selectedOffer.promo?.code,
        promoValue: selectedOffer.promo?.value,
        rhythmKwhRate: parseFloat(selectedOffer.rhythmKwhRate),
        solarCreditKwhRate: selectedOffer.solarCreditKwhRate,
        solarEligible: selectedOffer.solarEligible,
        termMonths: selectedOffer.termMonths,
        termsOfServiceEn: selectedOffer.termsOfServiceEn,
        termsOfServiceEs: selectedOffer.termsOfServiceEs,
        title: selectedOffer.title,
      };
      offersnapshotId = selectedOffer?.id;
    }

    if (!referralCode) {
      updateProspectState({
        autopay: selectedOffer?.isAutoPayPaperlessDiscountOffer,
        isPaperless: selectedOffer?.isAutoPayPaperlessDiscountOffer,
        offersnapshot,
        offersnapshotId,
        referralCode: null,
      });
      return;
    }

    validateRafReferralCode(referralCode).then((success) => {
      if (success) {
        updateProspectState({
          autopay: selectedOffer?.isAutoPayPaperlessDiscountOffer,
          isPaperless: selectedOffer?.isAutoPayPaperlessDiscountOffer,
          offersnapshot,
          offersnapshotId,
          referralCode,
        });
      }
    });
  };

  const handleReferralCode = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setReferralCode(event.target.value);
  };

  const isAcquisitionCampaingRequired = !(acquisitionMedium === "broker");
  const errorMessage = (() => {
    const campaignErrorTitle =
      !isAcquisitionCampaingRequired || acquisitionCampaign ? "" : "Campaign";
    const meterErrorTitle = meterId ? "" : "Meter";
    const connector = campaignErrorTitle && meterErrorTitle ? "and" : "";

    return `${campaignErrorTitle} ${connector} ${meterErrorTitle} must be entered before Plans can be viewed`;
  })();

  let showOfferCard = false;

  if (isAcquisitionCampaingRequired) {
    showOfferCard = !!acquisitionCampaign && !!meterId;
  } else {
    showOfferCard = !!meterId;
  }

  return (
    <StyledTab>
      <form noValidate onSubmit={onSubmit} id={TabType.plan}>
        <StyledContainer>
          <BoCard>
            <SelectorContainer>
              <BoTextInput
                name="referralCode"
                onChange={handleReferralCode}
                value={referralCode}
                disabled={rafReferralRequestMonitor.isPending}
              >
                <StyledTitleText>Referral Code</StyledTitleText>
              </BoTextInput>
            </SelectorContainer>
          </BoCard>

          {dunsNumber && showOfferCard ? (
            <BoCard>
              <OfferCardRadioGroup
                offerSnapshotListSearchParams={{
                  dunsNumber,
                  rcid,
                }}
                onOfferSnapshotSelect={handleOfferSelect}
                initialSelectedOfferSnapshotId={existingOffersnapshotId}
              />
            </BoCard>
          ) : (
            <StyledErrorContainer>{errorMessage}</StyledErrorContainer>
          )}
        </StyledContainer>
      </form>
    </StyledTab>
  );
};
