import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface CustomerEmailUpdatedData extends EventLogBaseData {
  newEmail: string;
  oldEmail: string;
}

export class CustomerEmailUpdated extends EventLog {
  public readonly oldEmail: string;
  public readonly newEmail: string;

  constructor({
    oldEmail,
    newEmail,
    ...baseEventData
  }: CustomerEmailUpdatedData) {
    super({
      ...baseEventData,
      eventText: "Updated Email",
    });

    this.oldEmail = oldEmail;
    this.newEmail = newEmail;
  }

  override get body() {
    return `${this.name} - From: ${this.oldEmail} - To: ${this.newEmail}`;
  }
}
