import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { UUIDType } from "@common/types/apiTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import { stringOrEmDash } from "@common/utils/stringFormatters";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import { BoLink } from "@ops-design-system/components/BoLink/BoLink";
import { Body3 } from "@ops-design-system/components/Typography/Typography";
import { ConfirmationSection } from "@ops/components/ProspectEditor/ConfirmationTab/ConfirmationSection";
import { ConfirmationCardType } from "@ops/components/ProspectEditor/ConfirmationTab/prospectConfirmationTypes";
import { useProductPrices } from "@ops/hooks/queries/useProductPrices";
import React from "react";

interface ConfirmationProductAddonsCardProps extends ConfirmationCardType {}

const ConfirmationProductAddonsCardContent = ({
  selectedProductPriceId,
}: {
  selectedProductPriceId: UUIDType;
}) => {
  const productPricesQuery = useProductPrices();

  if (productPricesQuery.isPending) {
    return <RhCircularProgress />;
  }

  if (productPricesQuery.isError) {
    return (
      <div>
        Error loading products. We can’t display this information right now.
      </div>
    );
  }

  const selectedProductPrice = productPricesQuery.data.find((productPrice) => {
    return productPrice.id === selectedProductPriceId;
  });

  if (!selectedProductPrice) {
    return <div>No product selected</div>;
  }

  const selectedProduct = selectedProductPrice.product;

  return (
    <BoGrid $variant={GridVariants.Grid4_1}>
      <BoGridColumn>
        <BoInfoLabel id="label-additional-service">
          Additional Service
        </BoInfoLabel>
        <BoInfoValue aria-labelledby="label-additional-service">
          {stringOrEmDash(selectedProduct.name)}
        </BoInfoValue>
      </BoGridColumn>
      <BoGridColumn>
        <BoInfoContainer>
          <BoInfoLabel id="label-monthly-cost">Monthly Cost</BoInfoLabel>
          <BoInfoValue aria-labelledby="label-monthly-cost">
            {formatCurrency(selectedProductPrice.rate)}
          </BoInfoValue>
        </BoInfoContainer>
      </BoGridColumn>
      <BoGridColumn>
        <BoInfoLabel id="label-term">Term</BoInfoLabel>
        <BoInfoValue aria-labelledby="label-term">
          {selectedProductPrice.termLength} mos
        </BoInfoValue>
      </BoGridColumn>
      <BoGridColumn>
        <BoLink>
          <Body3>Welcome Packet</Body3>
        </BoLink>
      </BoGridColumn>
    </BoGrid>
  );
};

export const ConfirmationProductAddonsCard = ({
  prospect,
  errors,
}: ConfirmationProductAddonsCardProps) => {
  const { featureFlagClient } = useFeatureFlagClient();
  const { opsProductAddOnsEnrollment: productAddonsEnrollmentFlag } =
    featureFlagClient.getFlags([["opsProductAddOnsEnrollment", false]]);

  if (!productAddonsEnrollmentFlag.value) {
    return null;
  }

  const { productPrices } = prospect;
  const selectedProductPriceId = productPrices?.[0];

  return (
    <ConfirmationSection label="Additional Services" error={Boolean(errors)}>
      {selectedProductPriceId ? (
        <ConfirmationProductAddonsCardContent
          selectedProductPriceId={selectedProductPriceId}
        />
      ) : (
        <>No product selected</>
      )}
    </ConfirmationSection>
  );
};
