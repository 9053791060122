import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { Balance } from "@common/types/accountSummaryTypes";
import { IdType } from "@common/types/apiTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import {
  Body2,
  Body3,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as ChevronDown } from "@ops-design-system/icons/ChevronDown.svg";
import { orange } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { StyledPastDue } from "@ops/components/PremiseBillingCard/PremiseBillingCard.styled";
import { useAccountSummaryQuery } from "@ops/hooks/queries/useAccountSummaryQuery";
import React, { useState } from "react";
import styled from "styled-components";

export interface PremiseBillingBalanceBreakdownProps {
  premiseId: IdType;
}

const WarningText = styled(Body3)`
  color: ${orange.main};
`;

const BalanceBreakdownTitle = styled(Body2)`
  font-weight: 600;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding-bottom: ${rhOpsSpacingPx(1.5)};
`;

const BreakdownRow = ({
  type: label,
  balances,
}: {
  balances: Balance;
  type: "Energy" | "Add On";
}) => {
  // TODO: Replace with useId() hook in React 18
  const id = `balance-breakdown-${label.replace(" ", "-").toLowerCase()}`;

  return (
    <>
      <BoGridColumn>
        <BoInfoContainer>
          <BoInfoLabel id={`${id}-due`}>{label} Due</BoInfoLabel>
          <BoInfoValue>
            <Subtitle2 aria-labelledby={`${id}-due`}>
              {formatCurrency(balances.totalBalance)}
            </Subtitle2>
          </BoInfoValue>
        </BoInfoContainer>
      </BoGridColumn>
      <BoGridColumn>
        <BoInfoContainer>
          <BoInfoLabel id={`${id}-past-due`}>{label} Past Due</BoInfoLabel>
          <BoInfoValue>
            <StyledPastDue
              aria-labelledby={`${id}-past-due`}
              $pastDueBalance={balances.pastDueBalance}
            >
              {formatCurrency(balances.pastDueBalance)}
            </StyledPastDue>
          </BoInfoValue>
        </BoInfoContainer>
      </BoGridColumn>
    </>
  );
};

const BalanceBreakdown = ({
  premiseId,
}: PremiseBillingBalanceBreakdownProps) => {
  const accountSummaryQuery = useAccountSummaryQuery(premiseId);

  if (accountSummaryQuery.isPending) {
    return <RhCircularProgress />;
  }
  if (accountSummaryQuery.isError) {
    return <WarningText>Error fetching data</WarningText>;
  }

  const { balanceBreakdown } = accountSummaryQuery.data;

  if (!balanceBreakdown) {
    return <WarningText>No data available</WarningText>;
  }

  return (
    <BoGrid>
      <BreakdownRow type="Energy" balances={balanceBreakdown.energy} />
      <BreakdownRow type="Add On" balances={balanceBreakdown.product} />
    </BoGrid>
  );
};

export const PremiseBillingBalanceBreakdown = ({
  premiseId,
}: PremiseBillingBalanceBreakdownProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { featureFlagClient } = useFeatureFlagClient();

  const { opsProductAddOns } = featureFlagClient.getFlags([
    ["opsProductAddOns", false],
  ]);

  if (!opsProductAddOns.value) {
    return null;
  }

  return (
    <div>
      <TitleRow
        onClick={() => {
          setIsExpanded((expanded) => !expanded);
        }}
      >
        <BalanceBreakdownTitle>Balance breakdown</BalanceBreakdownTitle>

        <span
          style={{
            alignItems: "center",
            display: "flex",
            paddingLeft: "0.5ch",
          }}
        >
          <ChevronDown
            height="1em"
            width="1em"
            style={{
              paddingBottom: `${1 / 4}em`,
              // Set the transform origin to the center of the icon
              // transformOrigin: "50% 50%",
              transform: isExpanded ? "scaleY(-1)" : "scaleY(1)",
            }}
          />
        </span>
      </TitleRow>
      {isExpanded && <BalanceBreakdown premiseId={premiseId} />}
    </div>
  );
};
