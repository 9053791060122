import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { ButtonsGrid } from "@ops-design-system/components/BoGrid/BoGrid";
import { BoLinkButton } from "@ops-design-system/components/BoLinkButton/BoLinkButton";
import { ReactComponent as Cog } from "@ops-design-system/icons/Cog.svg";
import { Section } from "@ops/components/AdminDashboard/shared/AdminDashboard.styled";
import { adminPaths } from "@ops/routes/routePaths";
import React from "react";

export const BillingSection = () => {
  const { featureFlagClient } = useFeatureFlagClient();

  const { opsReprocessInvoice } = featureFlagClient.getFlags([
    ["opsReprocessInvoice", false],
  ]);

  const sectionName = "Billing & EDI Messages";

  return (
    <Section aria-label={sectionName}>
      <BoCardLabel>{sectionName}</BoCardLabel>
      <BoCard>
        <ButtonsGrid>
          {opsReprocessInvoice.value ? (
            <BoLinkButton
              variant="secondary"
              icon={Cog}
              to={adminPaths.reprocessInvoice()}
            >
              Reprocess Invoice
            </BoLinkButton>
          ) : null}
          <BoLinkButton
            variant="secondary"
            icon={Cog}
            to={adminPaths.transferEdiMessage()}
          >
            Transfer EDI Messages
          </BoLinkButton>
        </ButtonsGrid>
      </BoCard>
    </Section>
  );
};
