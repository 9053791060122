import { EM_DASH } from "@common/constants/characters.constant";
import { KILOWATT_HOURS_ABBREV } from "@common/constants/other.constant";
import { IdType } from "@common/types/apiTypes";
import {
  formatCurrency,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { ReactComponent as SunIcon } from "@design-system/icons/SunIcon.svg";
import { ReactComponent as WarningIcon } from "@design-system/icons/WarningIcon.svg";
import { BoCardLabel } from "@ops-design-system/components/BoCard/BoCard";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import { BoLink } from "@ops-design-system/components/BoLink/BoLink";
import { darkPurple } from "@ops-design-system/styles/palette.colors";
import {
  StyledBoInfoValue,
  StyledCard,
  StyledEndDateContainer,
  StyledWarningIcon,
  UnavailableMessage,
} from "@ops/components/PlanInfo/PlanInfo.styled";
import { PremiseDocumentsList } from "@ops/components/PremiseDocumentsList/PremiseDocumentsList";
import { TOUEnergyBreakdownModals } from "@ops/components/TOUEnergyBreakdownModals/TOUEnergyBreakdownModals";
import { formatEnergyRate } from "@ops/formatters";
import { usePremise } from "@ops/hooks/usePremise";
import dayjs from "dayjs";
import React from "react";
import { useParams } from "react-router-dom";

interface PlanInfoProps {
  showDocuments?: boolean;
  showRenewalPeriodWarning?: boolean;
}

export const PlanInfo = ({
  showDocuments = true,
  showRenewalPeriodWarning = false,
}: PlanInfoProps) => {
  const { premiseId } = useParams<{ premiseId: IdType }>();

  const { premise, error } = usePremise(premiseId);

  if (error || !premise) {
    return null;
  }

  const { currentOrder: order } = premise;

  if (!order) {
    return (
      <UnavailableMessage>
        It appears this user does not have an active order so we have no Plan
        data to show here.
      </UnavailableMessage>
    );
  }

  let remainingMonths = EM_DASH;
  let cancellationFee = EM_DASH;
  const shouldDisplayCancellationInfo =
    !premise.serviceEndDate &&
    order.contractEndDate &&
    dayjs() <= dayjs(order.contractEndDate);

  if (shouldDisplayCancellationInfo) {
    remainingMonths = `${order.remainingMonths} months`;
    cancellationFee = formatCurrency(order.cancellationFee);
  }

  return (
    <>
      <BoGrid>
        <BoGridColumn>
          <BoInfoContainer>
            <BoInfoLabel>Current Plan </BoInfoLabel>
            <StyledBoInfoValue>
              {
                // Product explicity asked for this to use the offer snapshot over the orders solar eligible status
                order.offerSnapshotSolarEligible ? (
                  <SunIcon
                    color={darkPurple["500"]}
                    data-testid="sun-icon"
                    height={16}
                    width={16}
                  />
                ) : null
              }
              {order.title}
            </StyledBoInfoValue>
          </BoInfoContainer>
          <BoInfoContainer>
            <BoInfoLabel>Rhythm Energy Rate</BoInfoLabel>
            <BoInfoValue>
              {order.isTimeOfUse ? (
                <TOUEnergyBreakdownModals
                  offerSnapshotId={order.offersnapshotId}
                />
              ) : (
                `${order.contractRate.valueOf()} ${EM_DASH} ${formatEnergyRate(
                  order.energyRate,
                  3
                )}`
              )}
            </BoInfoValue>
          </BoInfoContainer>
          <BoInfoContainer>
            <BoInfoLabel>{`Average Price At Signup (2000${KILOWATT_HOURS_ABBREV})`}</BoInfoLabel>
            <BoInfoValue>
              {formatEnergyRate(order.averageRateAt2000Kwh)}
            </BoInfoValue>
          </BoInfoContainer>
          <BoInfoContainer>
            <BoInfoLabel id="cancellation-fee">Cancellation fee</BoInfoLabel>
            <BoInfoValue aria-labelledby="cancellation-fee">
              {cancellationFee}
            </BoInfoValue>
          </BoInfoContainer>
        </BoGridColumn>
        <BoGridColumn>
          <BoInfoContainer>
            <BoInfoLabel>Contract Start Date</BoInfoLabel>
            <BoInfoValue>{formatMonthDayYear(order.startDate)}</BoInfoValue>
          </BoInfoContainer>
          <StyledEndDateContainer
            $showRenewalPeriodWarning={showRenewalPeriodWarning}
          >
            <BoInfoLabel>Contract End Date</BoInfoLabel>
            <StyledBoInfoValue>
              {showRenewalPeriodWarning && (
                <StyledWarningIcon>
                  <WarningIcon width={16} height={17} />
                </StyledWarningIcon>
              )}
              {formatMonthDayYear(order.contractEndDate)}
            </StyledBoInfoValue>
          </StyledEndDateContainer>

          <BoInfoContainer>
            <BoInfoLabel>Term</BoInfoLabel>
            <BoInfoValue>{order.termMonths} months</BoInfoValue>
          </BoInfoContainer>
          <BoInfoContainer>
            <BoInfoLabel id="remaining-months">Remaining months</BoInfoLabel>
            <BoInfoValue aria-labelledby="remaining-months">
              {remainingMonths}
            </BoInfoValue>
          </BoInfoContainer>
        </BoGridColumn>
      </BoGrid>
      {showDocuments ? <PremiseDocumentsList /> : null}
      <BoInfoContainer>
        <BoInfoLabel>
          Terms of Service -{" "}
          {order.termsOfServiceVersion
            ? `V${order.termsOfServiceVersion}`
            : "Default"}
        </BoInfoLabel>
        {order.termsOfServiceEn ? (
          <BoInfoValue>
            <BoLink href={order.termsOfServiceEn}>View</BoLink>
          </BoInfoValue>
        ) : null}
      </BoInfoContainer>
    </>
  );
};

export const PlanInfoCard = (props: PlanInfoProps) => {
  const sectionName = "Current Plan";
  const { premiseId } = useParams<{ premiseId: IdType }>();
  const { premise } = usePremise(premiseId);
  const showRenewalPeriodWarning = premise?.inRenewalWindow;

  return (
    <section aria-label={sectionName}>
      <BoCardLabel>{sectionName}</BoCardLabel>
      <StyledCard $showRenewalPeriodWarning={showRenewalPeriodWarning}>
        <PlanInfo
          {...props}
          showRenewalPeriodWarning={showRenewalPeriodWarning}
        />
      </StyledCard>
    </section>
  );
};
