import { EnrollmentStatus, EnrollmentType } from "@common/enums/customer.enum";
import { PremiseStatus } from "@common/enums/premise.enum";
import { Address } from "@common/models/Address.model";
import { AddressForm } from "@common/models/AddressForm.model";
import { Meter } from "@common/models/Meter.model";
import { Order } from "@common/models/Order.model";
import { IdType, UUIDType } from "@common/types/apiTypes";
import {
  DisconnectNoticeType,
  PaymentExtensionType,
} from "@common/types/billingTypes";
import { AddressFormType, AddressType } from "@common/types/customerTypes";
import { OrderStatus } from "@common/types/orderTypes";
import {
  CommunicationPreferenceType,
  PremiseSourceType,
  PremiseType,
} from "@common/types/premiseTypes";
import { StatusIndicatorLevel } from "@common/types/statusIndicatorTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import dayjs from "dayjs";
import lodash, { isEmpty } from "lodash";
import orderBy from "lodash/orderBy";

export const premiseStatusLevel = {
  [PremiseStatus.ACTIVE]: StatusIndicatorLevel.SUCCESS,
  [PremiseStatus.INACTIVE]: StatusIndicatorLevel.ALERT,
  [PremiseStatus.PENDING]: StatusIndicatorLevel.WARN,
};

export class Premise {
  public autopay: boolean;
  public averagedBilling: boolean;
  public averagedBillingBalance: number | null;
  public averagedBillingMonthlyCharge: number | null;
  public billingAccountId: string;
  public billingAccountNumber: string;
  public billingPaymentMethodId: string | null;
  public communicationPreference: CommunicationPreferenceType;
  public confirmedStartDate: string | null;
  public customerId: IdType;
  public customerUuid: UUIDType;
  public endDate: string;
  public enrollmentStatus: EnrollmentStatus;
  public enrollmentType: EnrollmentType;
  public hasActiveDpp: boolean;
  public hasHistoricUsage!: boolean;
  public id: IdType;
  public inRenewalWindow: boolean;
  public canUpdateMoveOutEndDate: boolean;
  public invoiceByPrint: boolean;
  public readonly isNoCheckOrAch: boolean;
  public readonly isNoCreditOrDebitCard: boolean;
  public lastPyddInvoiceDate: string | null;
  public lifeSupport: boolean;
  public mailingAddress: AddressForm | null;
  public meter: Meter | null;
  public orders: Order[];
  private readonly paymentExtensions?: PaymentExtensionType[];
  public pickedBillingDueDay: number | null;
  private readonly premServiceAddress: AddressType | null;
  private readonly premZuoraSetupServiceAddress: AddressFormType | null;
  public serviceEndDate: string | null;
  public serviceRequestedDate: string | null;
  public readonly source: PremiseSourceType;
  public status: PremiseStatus;
  public thermostatManagementMonthlyDiscount: string;
  public uuid: UUIDType;
  private readonly unsortedDisconnectNotices?: DisconnectNoticeType[];

  public constructor(premise: PremiseType) {
    this.autopay = premise.autopay;
    this.averagedBilling = premise.averagedBilling;
    this.averagedBillingBalance = premise.averagedBillingBalance;
    this.averagedBillingMonthlyCharge = premise.averagedBillingMonthlyCharge;
    this.billingAccountId = premise.billingAccountId;
    this.billingAccountNumber = premise.billingAccountNumber;
    this.billingPaymentMethodId = premise.billingPaymentMethodId;
    this.communicationPreference = premise.communicationPreference;
    this.confirmedStartDate = premise.confirmedStartDate;
    this.customerId = premise.customerId;
    this.customerUuid = premise.customerUuid;
    this.endDate = premise.endDate;
    this.enrollmentStatus = premise.enrollmentStatus;
    this.enrollmentType = premise.enrollmentType;
    this.hasActiveDpp = premise.hasActiveDpp;
    this.hasHistoricUsage = premise.hasHistoricUsage;
    this.id = premise.id;
    this.inRenewalWindow = premise.inRenewalWindow;
    this.canUpdateMoveOutEndDate = premise.canUpdateMoveOutEndDate;
    this.invoiceByPrint = premise.invoiceByPrint;
    this.isNoCheckOrAch = premise.isNoCheckOrAch;
    this.isNoCreditOrDebitCard = premise.isNoCreditOrDebitCard;
    this.lastPyddInvoiceDate = premise.lastPyddInvoiceDate;
    this.lifeSupport = premise.lifeSupport;
    this.mailingAddress = premise.mailingAddress
      ? new AddressForm(premise.mailingAddress)
      : null;
    this.meter = premise.meter ? new Meter(premise.meter) : null;
    this.orders = premise.orders
      .map((order) => new Order(order))
      .sort((a, b) => {
        return dayjs(a.startDate) >= dayjs(b.startDate) ? 1 : -1;
      });
    this.paymentExtensions = premise.paymentExtensions;
    this.pickedBillingDueDay = premise.pickedBillingDueDay;
    this.premServiceAddress = premise.serviceAddress;
    this.premZuoraSetupServiceAddress = premise.zuoraSetupServiceAddress;
    this.serviceEndDate = premise.serviceEndDate;
    this.serviceRequestedDate = premise.serviceRequestedDate;
    this.source = premise.source;
    this.status = premise.status;
    this.thermostatManagementMonthlyDiscount =
      premise.thermostatManagementMonthlyDiscount;
    this.uuid = premise.uuid;
    this.unsortedDisconnectNotices = premise.disconnectNotices;
  }

  private get activeOrder() {
    // Current active order should be the newest active order.
    return lodash.last(
      this.orders.filter((order) => order.status === OrderStatus.Active)
    );
  }

  public get futureOrder() {
    return this.orders.filter(
      (order) => order.status === OrderStatus.Enrolled
    )[0];
  }

  private get uncreatedOrder() {
    return this.orders.filter(
      (order) => order.status === OrderStatus.Uncreated
    )[0];
  }

  public get serviceAddress(): Address | null {
    if (this.premServiceAddress) {
      return new Address(this.premServiceAddress);
    } else if (
      this.premZuoraSetupServiceAddress &&
      !isEmpty(this.premZuoraSetupServiceAddress)
    ) {
      return Address.fromAddressForm(this.premZuoraSetupServiceAddress);
    }
    return null;
  }

  disconnectNoticeForInvoices(invoiceId: string) {
    return (this.disconnectNotices ?? [])
      .filter((dnp) => dnp.zuoraInvoiceId === invoiceId)
      .sort(
        (a, b) =>
          new Date(b.disconnectionDate).getTime() -
          new Date(a.disconnectionDate).getTime()
      );
  }

  public get invoiceByEmailOnly(): boolean {
    return Boolean(!this.invoiceByPrint);
  }

  public get isPaperless() {
    return (
      this.communicationPreference === CommunicationPreferenceType.PAPERLESS
    );
  }

  public get isPrint() {
    return this.communicationPreference === CommunicationPreferenceType.PRINT;
  }

  public get currentOrder() {
    if (this.activeOrder) {
      return this.activeOrder;
    } else if (this.futureOrder) {
      return this.futureOrder;
    } else if (this.uncreatedOrder) {
      return this.uncreatedOrder;
    } else if (this.orders) {
      return lodash.last(this.orders);
    } else {
      return null;
    }
  }

  public get currentPaymentExtension() {
    return (
      this.paymentExtensionsByExtendToDate &&
      this.paymentExtensionsByExtendToDate[0]
    );
  }

  public get disconnectNotices() {
    return this.disconnectNoticesBySentDate;
  }

  public get isEnrollmentRequested(): boolean {
    return this.enrollmentStatus === EnrollmentStatus.ENROLLMENT_REQUESTED;
  }

  public get isEnrollmentAccepted(): boolean {
    return this.enrollmentStatus === EnrollmentStatus.ENROLLMENT_ACCEPTED;
  }

  public get isDisconnected(): boolean {
    return this.enrollmentStatus === EnrollmentStatus.STATUS_DISCONNECTED;
  }

  public get isMoveOutRequested(): boolean {
    return this.enrollmentStatus === EnrollmentStatus.MOVE_OUT_REQUESTED;
  }

  public get isMoveOutAccepted(): boolean {
    return this.enrollmentStatus === EnrollmentStatus.MOVE_OUT_ACCEPTED;
  }

  public get isMoveOutDateChangeRequested(): boolean {
    return (
      this.enrollmentStatus === EnrollmentStatus.MOVE_OUT_DATE_CHANGE_REQUESTED
    );
  }

  public get isCancelled(): boolean {
    return this.enrollmentStatus === EnrollmentStatus.CANCELED;
  }

  public get isOnLegacyEBill(): boolean {
    return this.communicationPreference === CommunicationPreferenceType.EBILL;
  }

  public get isConfirmationRequiredForEnrollmentStatus(): boolean {
    const statusNotRequireResubmitConfirmation = [
      EnrollmentStatus.CANCELED,
      EnrollmentStatus.PRE_ENROLL,
      EnrollmentStatus.ENROLLMENT_REQUESTED,
    ];

    return !statusNotRequireResubmitConfirmation.includes(
      this.enrollmentStatus
    );
  }

  public get isPaymentTransactionFeeAllowed(): boolean {
    return Number(this.currentOrder?.termsOfServiceVersion || 0) >= 7;
  }

  public get historicOrders(): Order[] {
    return this.orders.filter((value, index, array) => {
      if (value === this.futureOrder) {
        return false;
      } else if (value === this.currentOrder) {
        return false;
      }

      return true;
    });
  }

  public get latestDisconnectNotice() {
    if (this.disconnectNoticesBySentDate.length === 0) {
      return null;
    }

    return this.disconnectNoticesBySentDate[
      this.disconnectNoticesBySentDate.length - 1
    ];
  }

  public get formattedThermostatManagementMonthlyDiscount() {
    const decimals =
      Number(this.thermostatManagementMonthlyDiscount) % 1 === 0 ? 0 : 2;

    return formatCurrency(this.thermostatManagementMonthlyDiscount, decimals);
  }

  private get paymentExtensionsByExtendToDate() {
    return orderBy(
      [...(this.paymentExtensions ?? [])],
      ["extendToDate"],
      ["desc"]
    );
  }

  private get disconnectNoticesBySentDate() {
    return orderBy(
      [...(this.unsortedDisconnectNotices ?? [])],
      ["sentToLobAt"],
      ["asc"]
    );
  }
}
