import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import { SelectVariants } from "@ops-design-system/components/BoSelectInput/BoSelect.styled";
import {
  BoSelectInput,
  BoSelectOptionType,
} from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { useSupportLogsContext } from "@ops/components/SupportLogs/hooks/useSupportLogsContext";
import { StyledHeaderContainer } from "@ops/components/SupportLogs/SupportLogs.styled";
import {
  LogType,
  TargetType,
} from "@ops/components/SupportLogs/SupportLogs.types";
import { isLocal } from "@ops/utils/env.util";
import React, { PropsWithChildren } from "react";

const customerSelectOptions: BoSelectOptionType[] = [
  { label: "Contact Log", value: LogType.CONTACT },
  { label: "Event Log", value: LogType.EVENT },
];

const prospectSelectOptions: BoSelectOptionType[] = [
  { label: "Contact Log", value: LogType.CONTACT },
  { label: "Validation Report", value: LogType.VALIDATION },
];

const AvailableSelectOptions = {
  [TargetType.CUSTOMER]: customerSelectOptions,
  [TargetType.PROSPECT]: prospectSelectOptions,
} as const;

const getSelectOptions = (
  targetType: TargetType,
  useValidationReport = false
): BoSelectOptionType[] => {
  const selectOptions = AvailableSelectOptions[targetType];

  if (targetType === TargetType.PROSPECT && !useValidationReport) {
    return selectOptions.filter(({ value }) => value !== LogType.VALIDATION);
  }

  return selectOptions;
};

interface HeaderProps {}

export const Header = ({ children }: PropsWithChildren<HeaderProps>) => {
  const { featureFlagClient } = useFeatureFlagClient();
  const { opsAlloyValidationReport } = featureFlagClient.getFlags([
    ["opsAlloyValidationReport", false],
  ]);

  const { target, activeLog, setActiveLog } = useSupportLogsContext();

  const handleLogSelect = (option: BoSelectOptionType | null) => {
    if (option) {
      setActiveLog(option.value as LogType);
    }
  };

  const availableOptions = getSelectOptions(
    target.type,
    Boolean(opsAlloyValidationReport.value) || isLocal()
  );

  return (
    <StyledHeaderContainer>
      <BoSelectInput
        ariaLabel="Log Type Select"
        value={activeLog}
        onChange={handleLogSelect}
        options={availableOptions}
        variant={SelectVariants.Borderless}
      />
      {children && <BoFlexBox $alignItems="center">{children}</BoFlexBox>}
    </StyledHeaderContainer>
  );
};
