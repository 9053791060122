import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { EM_DASH } from "@common/constants/characters.constant";
import { BoLink } from "@ops-design-system/components/BoLink/BoLink";
import React from "react";

export const ProductContractTosLink = ({ tosUrl }: { tosUrl: string }) => {
  const { featureFlagClient } = useFeatureFlagClient();
  const { opsShowViewTosLink } = featureFlagClient.getFlags([
    ["opsShowViewTosLink", false],
  ]);
  const shouldShowTosLink = opsShowViewTosLink.value;

  if (!shouldShowTosLink) {
    return <>{EM_DASH}</>;
  }

  return (
    <BoLink href={tosUrl} target="_blank" title="View Terms and Conditions">
      View
    </BoLink>
  );
};
